import s from "../styles/layout.module.scss";
import common from "../styles/common.module.scss";

export default function Footer() {
  return (
    <footer id={s.footer}>
      <div className={`${common.inner_short} ${common.footer_inner}`}>
        <div className={s.contents}>
          <div className={s.top}>
            <span>(주)자람콘텐츠</span>
            {/* <ul>
              <li>
                <a href='/' target='_blank'>
                  이용약관
                </a>
              </li>
              <li>
                <a href='/' target='_blank'>
                  개인정보처리방침
                </a>
              </li>
              <li>
                <a href='/' target='_blank'>
                  위치기반서비스 이용약관
                </a>
              </li>
            </ul> */}
          </div>
          <div className={s.bottom}>
            <div className={s.info}>
              <ul>
                <li>대표자</li>
                <li>정영찬 대표</li>
              </ul>
              <ul>
                <li>사업자등록번호</li>
                <li>529-87-00609</li>
              </ul>
              <ul>
                <li>Phone</li>
                <li>050-730-0608 </li>
              </ul>
              <ul>
                <li>Email</li>
                <li>jaram@limefriends.com</li>
              </ul>
              <ul>
                <li>주소</li>
                <li className={s.addr}>
                  서울특별시 금천구 가산디지털1로 16(가산동){" "}
                  <br className={common.foo_mobile} />
                  가산2차 SK V1 AP tower 611호
                </li>
              </ul>
            </div>
            <span className={s.copyright}>
              Copyright ©Lime Friends Co., Ltd. <br />
              All rights reserved
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
}
