import {Link} from "react-router-dom";
import s from "../../styles/landing.module.scss";
import ebook from "../../styles/Ebook.module.scss";
import common from "../../styles/common.module.scss";

export default function EbookPage() {
  return (
    <main className={s.main}>
      <div className={common.wrap}>
        <Section1 />
        <Section2 />
      </div>
    </main>
  );
}

function Section1() {
  return (
    <section className={`${ebook.section} ${ebook.section1}`}>
      <div className={`${common.area}`}>
        <div className={ebook.wrapper}>
          {
            /* <img src={'/assets/img_section1.png'} alt='' />}*/
            <div className={ebook.contents}>
              <div className={ebook.banLogo}>
                <Link to="/">
                  <img src={"/assets/ebookIcon.png"} alt="" />
                </Link>
              </div>
              <h2>
                {/* 체험으로 <b>배우고,</b>
              <br />
              <span>경험으로</span> */}
                {/* <img src={'/assets/icon_img.png'} alt='' /> */}
                {/* <br className={common.mainMobile} />
              <b>자라나다.</b> */}
                자람 <strong>eBook 도서관</strong>
              </h2>
              <span className={ebook.link1Text}>
                자람 콘텐츠의 모든학습자료
              </span>
              <div className={ebook.app_links}>
                {/* <Link to='/' target='_blank'> */}
                <div className={ebook.btn2}>
                  <Link
                    to="https://apps.apple.com/us/app/%EC%9E%90%EB%9E%8Cebook%EB%8F%84%EC%84%9C%EA%B4%80/id6480269205"
                    target="_blank">
                    <img src={"/assets/btn2-icon.png"} alt="" />
                    <span>
                      Download on the <br />
                      app store
                    </span>
                  </Link>
                </div>
                {/* <Link to='/' target='_blank'> */}
                <div className={ebook.btn2}>
                  <Link
                    to="https://play.google.com/store/apps/details?id=com.limefriends.jaram_ebook_android"
                    target="_blank">
                    <img src={"/assets/btn1-icon.png"} alt="" />
                    <span>
                      Download on the <br />
                      google play
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </section>
  );
}

function Section2() {
  return (
    <section className={`${ebook.section} ${ebook.section2}`}>
      <div className={`${common.area} ${ebook.inner}`}>
        <div className={ebook.ebookHeader}>
          <h3 className={ebook.sec2Title}>eBook</h3>
          <span>
            다양한 주제와 수준에 맞는 현장체험학습 콘텐츠의 <br />
            eBook 자료를 제공합니다.
            <br />
            <br />* 모바일 어플리케이션에서 교재를 사용하기 위해서는 관리자
            코드가 필요합니다.
          </span>
        </div>
        <div className={ebook.eBookCont}>
          <ul>
            <li>
              <div className={ebook.listInner}>
                <div className={ebook.banLogo}>
                  <img src={"/assets/sign1.png"} alt="" />
                </div>
                <div className={ebook.downText}>
                  <span className={ebook.seoul}>서울투어</span>
                  <span className={ebook.mainTitle}>by. 자람 콘텐츠</span>
                  <span className={ebook.fileName}> PDF∙36p </span>
                </div>
              </div>
            </li>
            <li>
              <div className={ebook.listInner}>
                <div className={ebook.banLogo}>
                  <img src={"/assets/sign2.png"} alt="" />
                </div>
                <div className={ebook.downText}>
                  <span className={ebook.seoul}>국립중앙박물관-교재v1</span>
                  <span className={ebook.mainTitle}>by. 자람 콘텐츠</span>
                  <span className={ebook.fileName}> PDF∙32p </span>
                </div>
              </div>
            </li>
            <li>
              <div className={ebook.listInner}>
                <div className={ebook.banLogo}>
                  <img src={"/assets/sign3.png"} alt="" />
                </div>
                <div className={ebook.downText}>
                  <span className={ebook.seoul}>국립과천과학관-교재v1</span>
                  <span className={ebook.mainTitle}>by. 자람 콘텐츠</span>
                  <span className={ebook.fileName}> PDF∙36p </span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}
