import {Route, Routes} from "react-router-dom";
import LandingPage from "../component/landing/LandingPage";
import EbookPage from "../component/ebook/EbookPage";
import Layout from "../component/Layout";
import EbookLayout from "../component/EbookLayout";

export default function MyRoutes() {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route index path="/" element={<LandingPage />} />
        {/*<Route path='/contact' element={<ContactUs />} />*/}
        <Route path="*" element={<NotFound />} />
      </Route>
      <Route path="/ebook" element={<EbookLayout />}>
        <Route path="" element={<EbookPage />} />
      </Route>
    </Routes>
  );
}

function NotFound() {
  return <div className="inner">Not Found</div>;
}
