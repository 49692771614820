import React from "react";
import {Link, Outlet} from "react-router-dom";
import s from "../styles/layout.module.scss";
import common from "../styles/common.module.scss";
import Footer from "./Footer";

export default function Layout() {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
}

function Header() {
  return (
    <header id={s.header}>
      <div className={`${common.inner} ${s.inner}`}>
        <Link to="/" className={s.logo}>
          <img src={"/assets/logo.png"} className={s.pcLogo} alt="자람콘텐츠" />
          <img
            src={"/assets/mb_logo.png"}
            className={s.mBLogo}
            alt="자람콘텐츠"
          />
        </Link>
        <nav className={s.nav}>
          <Link to="https://jaramc.com/ebook">
            <span className={s.on}>eBOOK</span>
          </Link>
          <Link
            to="https://docs.google.com/forms/d/e/1FAIpQLSe3jyJzxb46hqlmF6bBhHp_0PKSFYwC84gTLfAjVb4pTdvSGA/viewform?vc=0&c=0&w=1&flr=0"
            target="_blank">
            <span>CONTACT US</span>
          </Link>
        </nav>
      </div>
    </header>
  );
}
