import React from "react";
import "./styles/reset.scss";
// import './styles/layout.module.scss';
import MyRoutes from "./routes/Routes";

function App() {
  return (
    <div className="App">
      <MyRoutes />
    </div>
  );
}

export default App;
