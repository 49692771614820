import {Link} from "react-router-dom";
import s from "../../styles/landing.module.scss";
import common from "../../styles/common.module.scss";

export default function LandingPage() {
  return (
    <main className={s.main}>
      <div className={common.wrap}>
        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 />
      </div>
      <Section5 />
    </main>
  );
}

function Section1() {
  return (
    <section className={`${s.section} ${s.section1}`}>
      <div className={`${common.area}`}>
        <div className={s.wrapper}>
          {/* <img src={'/assets/img_section1.png'} alt='' /> */}
          <div className={s.contents}>
            <h2>
              체험으로 <b>배우고,</b>
              <br />
              <span>경험으로</span>
              {/* <img src={'/assets/icon_img.png'} alt='' /> */}
              <br className={common.mainMobile} />
              <b>자라나다.</b>
            </h2>
            <div className={s.app_links}>
              {/* <Link to='/' target='_blank'> */}
              <div className={s.btn1}>
                <Link to="/">
                  <img src={"/assets/btn1-icon.png"} alt="" />

                  <span>Coming Soon</span>
                </Link>
              </div>

              {/* <Link to='/' target='_blank'> */}
              <div className={s.btn2}>
                <Link to="/">
                  <img src={"/assets/btn2-icon.png"} alt="" />
                  <span>Coming Soon</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function Section2() {
  return (
    <section className={`${s.section} ${s.section2}`}>
      <div className={`${common.area}`}>
        <div className={s.title_area}>
          <h2 className={s.section_title}>학습이 즐거움이 되는 콘텐츠</h2>
          <p>
            다양한 주제와 수준에 맞는 현장체험학습 콘텐츠를 제공하며, 학생들은
            다양한 주제와 수준에 맞는 현장체험학습
          </p>
        </div>

        <div className={s.contents}>
          {/* half */}
          <div className={s.item}>
            <div>
              <span>다양한 주제</span>
              <h3>
                온/오프라인 <br />
                체험교육
              </h3>
            </div>
            <Link className={s.link_btn} to="/">
              다양한 체험교육 보러가기
              <img src={"/assets/link_arrow.png"} alt="" />
            </Link>
          </div>
          <div className={s.contentsInner}>
            <div className={s.item_box}>
              {/* half */}
              <div className={s.item}>
                <p>다양한 주제와 수준에 맞는 현장체험학습 콘텐츠를 제공</p>
                <h3>직영 프로그램 </h3>
              </div>
              <div className={s.item}>
                <p>다양한 주제와 수준에 맞는 현장체험학습 콘텐츠를 제공</p>
                <h3>파트너사</h3>
              </div>
            </div>
            <div className={s.item}>
              <p>
                개인 및 소규모 수요자 대상의 B2C 체험시장 직영 프로그램 및
                파트너사를 통한 콘텐츠 공급
              </p>
              <h3>
                다양한 <br />
                콘텐츠 공급
              </h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function Section3() {
  return (
    <section className={`${s.section} ${s.section3}`}>
      <div className={`${common.area} ${s.inner}`}>
        <div>
          <span>맞춤형 콘텐츠</span>
          <h2 className={s.section_title}>
            각 학년에 맞는 <br />
            체험교육 콘텐츠 제공
          </h2>
          <p>
            다양한 분야의 체험활동을 한 곳에서 찾아볼 수 있는 플랫폼입니다. 자연
            탐험, 문화 체험, 과학 실험 등 다양한 주제의 체험활동을 통해 아이들의
            호기심과 창의력을 자극하세요.
          </p>
          <div className={s.downloadList}>
            <ul>
              <li>
                <Link
                  to="/files/Jaram_Seoul_1,2,3.pdf"
                  download={"Jaram_Seoul_1,2,3.pdf"}
                  target={"_blank"}
                  rel={"noreferrer"}>
                  <div className={s.listInner}>
                    <div className={s.downText}>
                      <span className={s.seoul}>서울</span>
                      <p>1~3학년 체험학습</p>
                    </div>
                    <div>
                      <img src={"/assets/download.png"} alt="다운로드" />
                    </div>
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  to="/files/Jaram_Seoul_4.pdf"
                  download={"Jaram_Seoul_4.pdf"}
                  target={"_blank"}
                  rel={"noreferrer"}>
                  <div className={s.listInner}>
                    <div className={s.downText}>
                      <span className={s.seoul}>서울</span>
                      <p>4학년 체험학습</p>
                    </div>
                    <div>
                      <img src={"/assets/download.png"} alt="다운로드" />
                    </div>
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  to="/files/Jaram_Seoul_5,6.pdf"
                  download={"Jaram_Seoul_5,6.pdf"}
                  target={"_blank"}
                  rel={"noreferrer"}>
                  <div className={s.listInner}>
                    <div className={s.downText}>
                      <span className={s.seoul}>서울</span>
                      <p>5~6학년 체험학습</p>
                    </div>
                    <div>
                      <img src={"/assets/download.png"} alt="다운로드" />
                    </div>
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  to="/files/Jaram_Gyeonggi_1,2,3.pdf"
                  download={"Jaram_Gyeonggi_1,2,3.pdf"}
                  target={"_blank"}
                  rel={"noreferrer"}>
                  <div className={s.listInner}>
                    <div className={s.downText}>
                      <span className={s.gyeonggiDo}>경기도</span>
                      <p>1~3학년 체험학습</p>
                    </div>
                    <div>
                      <img src={"/assets/download.png"} alt="다운로드" />
                    </div>
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  to="/files/Jaram_Gyeonggi_4,5,6.pdf"
                  download={"Jaram_Gyeonggi_4,5,6.pdf"}
                  target={"_blank"}
                  rel={"noreferrer"}>
                  <div className={s.listInner}>
                    <div className={s.downText}>
                      <span className={s.gyeonggiDo}>경기도</span>
                      <p>4~6학년 체험학습</p>
                    </div>
                    <div>
                      <img src={"/assets/download.png"} alt="다운로드" />
                    </div>
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  to="/files/Jaram_Landing_Web_Brochure.pdf"
                  download={"Jaram_Landing_Web_Brochure.pdf"}
                  target={"_blank"}
                  rel={"noreferrer"}>
                  <div className={s.listInner}>
                    <div className={s.downText}>
                      <span className={s.gyeonggiDo}>서울 및 경기</span>
                      <p>진로창의체험(전학년)</p>
                    </div>
                    <div>
                      <img src={"/assets/download.png"} alt="다운로드" />
                    </div>
                  </div>
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className={s.sec3Img}>
          <img
            src={"/assets/img_section3.png"}
            alt="배경이미지"
            className={s.sec3Bg}></img>
          <img
            src={"/assets/mb_img_section3.png"}
            alt="배경이미지"
            className={s.mbSec3Bg}></img>
        </div>
      </div>
    </section>
  );
}

function Section4() {
  return (
    <section className={`${s.section} ${s.section4}`}>
      <div className={common.area}>
        <div className={s.banner}>
          <span>B2C 체험교육 시장 구축</span>
          <h3>
            개인/소규모 수요자를 대상 <br />
            체험교육 서비스 구축
          </h3>
        </div>
        <div className={s.contents}>
          <div className={s.item}>
            <img src={"/assets/icon1_section4.png"} alt="" />
            <img src={"/assets/icon1_section4.png"} alt="" />
            <h3>파트너사를 통한 공급</h3>
            <p>
              체험교육 시장에서 콘텐츠 섭외는 초기에 낮은 수수료나 프로모션
              제안으로 빠르게 파트너 계약을 체결하고, 역사파트(박물관)를
              중심으로 다양한 카테고리별 체험운영사를 모집.
            </p>
            <span>20개의 파트너사 확보 예정</span>
          </div>
          <div className={s.item}>
            <img src={"/assets/icon2_section4.png"} alt="" />
            <h3>직영프로그램 구축</h3>
            <p>
              자체개발 콘텐츠로 수익성과 전문분야를 확립하고, 교육 특성에 맞는
              교구재 개발 예정. 교육용 교재 및 판촉물 등을 제작하고, 박물관
              프로그램을 중심으로 진행 예정.
            </p>
            <span>3개 이상의 프로그램을 세팅하고 운영 계획</span>
          </div>
          <div className={s.item}>
            <img src={"/assets/icon3_section4.png"} alt="" />
            <h3>청소년 활동진흥원 연계</h3>
            <p>
              청소년 수련활동 인증제의 공신력을 파트너사와 직영 프로그램에
              적용하여 홍보에 적극적으로 반영.
            </p>
            <span>진흥원 담당자와 소통과 협의를 통해 이익구조 구축 예정</span>
          </div>
        </div>
      </div>
    </section>
  );
}

function Section5() {
  return (
    <section className={`${s.section} ${s.section5}`}>
      <div>
        <div className={s.contents}>
          <p className={s.txt}>
            <strong>체험교육</strong>의<br className={common.mobile} />
            <strong>모든 것</strong>을 담다 <br className={common.mobile} />
            <br className={common.mobile2} />
            <strong>자람컨텐츠</strong>와 함께
            <br className={common.mobile} /> 시작해볼까요?
          </p>
          <p>자람콘텐츠와 함께할 파트너사를 찾습니다.</p>
          {/* <Link
            to='https://limefriends.com/'
            target='_blank'
            className={s.link_btn}
          >
            Contact Us
          </Link> */}
          <Link
            to="https://docs.google.com/forms/d/e/1FAIpQLSe3jyJzxb46hqlmF6bBhHp_0PKSFYwC84gTLfAjVb4pTdvSGA/viewform?vc=0&c=0&w=1&flr=0"
            target="_blank"
            className={s.link_btn}>
            Contact Us
          </Link>
        </div>
      </div>
    </section>
  );
}
